function getPointer(lat, lon) {
    return new ymaps.GeoObject({
        geometry: {
            type: "Point",
            coordinates: [lat, lon],
        },
    }, {
        iconLayout: 'default#image',
        iconImageHref: '/admin/static/images/pin.png',
        iconImageSize: [30, 30],
        draggable: true
    });
}


function setCoords(lat, lng) {
    let latInput = document.querySelector('#lat')
    latInput.value = lat
    let lngInput = document.querySelector('#lng')
    lngInput.value = lng
    // setAddress(lat, lng);
}
//Определяет адрес и заносит в инпут
function setAddress(lat, lng) {
    ymaps.geocode([lat, lng]).then(
        function (res) {
            let address = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData.text;
            let locationAddressInput = document.querySelector('#lat')
            locationAddressInput.value = address;
        }
    );
}

function movePointer(map, e) {
    let coords = e.get('coords'),
        lat = coords[0],
        lng = coords[1];
    map.geoObjects.removeAll();
    map.geoObjects.add(getPointer(lat, lng));
    setCoords(lat, lng);
}

function dragPointer(pointer) {
    let coords = pointer.geometry.getCoordinates();
    setCoords(coords[0], coords[1]);
}

function init() {
    let mapBlock = document.getElementById('map-with-search');
    let lat = mapBlock.dataset.lat,
        lon = mapBlock.dataset.lng,
        zoom = mapBlock.dataset.zoom,
        map = new ymaps.Map(mapBlock.id, {
            center: [lat, lon],
            zoom: zoom
        }),
        pointer = getPointer(lat, lon);
    setCoords(lat, lon);
    map.geoObjects.add(pointer);
    map.events.add('click', function (e) {
        movePointer(map, e);
    });
    pointer.events.add('dragend', function () {
        dragPointer(pointer);
    });
}

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('map-with-search')) {
        ymaps.ready(init);
    }
})
